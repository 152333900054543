// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="budgets">
//   <h1 data-target="budgets.output"></h1>
// </div>

import { Controller } from "stimulus"
import swal from 'sweetalert'
import Rails from '@rails/ujs'
import Inputmask from 'inputmask'
import QrCd from "easyqrcodejs"

// import { DataTable } from "simple-datatables"
import "datatables.net";
import "datatables.net-bs4";

import moment from 'moment'
import 'customs/daterangepicker.js'
import 'daterangepicker/daterangepicker.css'


import '../stylesheets/select2/select2.min.css'
import 'select2/dist/js/select2.full.min'
import 'select2/dist/js/i18n/pt-BR.js'

export default class extends Controller {
  static targets = [
    "bgFullAmount",
    "sectionNewCard",
    "sectionBankSlip",
    "card",
    "ccvContent",
    "ccv",
    "paymentType",
    "cardHolder",
    "cardNumber",
    "expirationDate" 
  ]

  connect() {
    if (this.data.has("actionForm")) {
      switch (this.data.get("actionForm")) {
        case "index": {
          this.initIndex()
          break
        }
        case "new": {
          this.formActions()
          break
        }
        case "external": {
          this.formExternal()
          break
        }
        case "show": {
          this.show()
          break
        }
        case "edit": {
          this.formActions()
          break
        }
      }
    }
  }

  show() {
    var qr = new QrCd(document.getElementById("qr-budget"), {
      text: gon.budget_link,
      width: 350,
      height: 350,
      logo: document.getElementById("logo").src,
      // backgroundImage: "/econecta-big-icon-bg-t.png",
      // backgroundImageAlpha: 0.3,
      colorLight: "#FFF8EB",
      colorDark: "#1c354c",
      dotScale: 1,
      quietZone: 12,
      quietZoneColor: "#54c7c3",
      // title: $('[name="organization-name"]')[0].content,
      title: "",
      titleTop: 20,
      titleColor: "#FFF",
      titleBackgroundColor: "#54c7c3",
      titleHeight: 32,
      titleFont: "bold 25px Arial",
    })
  }

  copy(e) {
    document.getElementById("extern_link").select()
    document.execCommand("copy")
    e.preventDefault()
  }

  formExternal(){
    new Inputmask("999").mask(document.getElementById("ccv"))
    new Inputmask("9999 9999 9999 9999").mask(document.getElementById("card_number"))
    new Inputmask({
      alias: "datetime",
      inputFormat: "mm/yy",
      placeholder: "__/__",
    }).mask(document.getElementById("expiration_date"))
  }

  changeCard(event) {
    if (!event || event.currentTarget.value == ""){
      this.sectionTokenCardsTarget.classList.add("payment-type--current")
      // this.sectionNewCardFieldsTarget.classList.remove("d-none")
      this.ccv2ContentTarget.classList.add("d-none")
    } else {
      this.sectionTokenCardsTarget.classList.remove("payment-type--current")
      // this.sectionNewCardFieldsTarget.classList.add("d-none")
      this.ccv2ContentTarget.classList.remove("d-none")
    }
  }

  checkCard(e) {
    if (this.luhn_checksum(e.currentTarget.value.replace(/[^\d]+/g, "")) != 0) {
      this.addErrorClass(e.currentTarget)
      e.currentTarget.nextElementSibling.innerHTML = "Cartão Inválido"
      this.error = true
    } else {
      e.currentTarget.nextElementSibling.innerHTML = ""
      e.currentTarget.parentElement.classList.remove("field_with_errors")
      e.currentTarget.parentElement.classList.remove("has-danger")
    }
  }

  addErrorClass(el) {
    el.parentElement.classList.add("field_with_errors")
    el.parentElement.classList.add("has-danger")
  }

  onPaymentSuccess(e) {
    setTimeout(() => {
      $("#modal-verify-data").modal('hide')
    }, 100)
    setTimeout(() => { Turbolinks.visit(e.detail[0].goto) }, 300 )
  }

  onPaymentError(e) {
    setTimeout(() => { $("#modal-verify-data").modal('hide') }, 250 )
    swal({
      title: "Ops!",
      text: e.detail[0].error,
      icon: "warning",
      dangerMode: true,
    }).then((ev) => {
      // Turbolinks.visit(e.detail[0].goto)
    })
  }

  onPaymentBeforeSend(e) {
    var error = this.validateErrorsForm()
    if(error){
      e.preventDefault()
      this.notifyMessage("Preencha os campos obrigatórios!")
    } else {
      $("#modal-verify-data").modal()
    }
   
  }

  validateErrorsForm() {
    this.error = false
    
    // Remover marcadores de erros
    document.querySelectorAll(`.field_with_errors`).forEach((i) => {
      i.classList.remove("field_with_errors")
      i.classList.remove("has-danger")
    })


    switch (this.paymentTypeTarget.value) {
      case "": {
        this.addErrorClass(this.paymentTypeTarget)
        this.paymentTypeTarget.nextElementSibling.innerHTML = "Seleciona a forma de Pagamento."
          this.error = true
          break
        }
      case "CreditCard":
      case "DebitCard": {


        document.querySelectorAll(`.card-data`).forEach((i) => {
          if (i.value == "") {
            this.addErrorClass(i)
            this.error = true
          }
        })
        if (this.luhn_checksum(document.budget.card_number.value.replace(/[^\d]+/g, "")) != 0) {
          this.addErrorClass(document.budget.card_number)
          document.budget.card_number.nextElementSibling.innerHTML = "Cartão Inválido"
          document.budget.card_number.focus()
          this.error = true
        }

        if(document.getElementById("ccv").value.replaceAll('_', '').length < 3 && this.paymentTypeTarget.value == "CreditCard"){
          document.budget.ccv.nextElementSibling.innerHTML = "Código inválido"
          this.error = true
        } else {
          document.budget.ccv.nextElementSibling.innerHTML = ""
        }
        break
      }
      case "bank_slip": {
        document.querySelectorAll(`.bank-slip-data`).forEach((i) => {
          if (i.value == "") {
            this.addErrorClass(i)
            this.error = true
          }
        })
        this.sectionBankSlipTarget.classList.add("payment-type--current")
        break
      }
    }
    return this.error
  }

  addErrorClass(el) {
    el.parentElement.classList.add("field_with_errors")
    el.parentElement.classList.add("has-danger")
  }

  notifyOption(){
    this.notifyMessage("Selecione uma das opções antes de seguir.")
  }

  notifyMessage(message){
    $.notify({
      icon: "nc-icon nc-bell-55",
      message: message
    }, {
      type: 'danger',
      timer: 1000,
      placement: { from: 'top', align: 'right' }
    });
  }

  paymentTypeChange(e) {
    for (let _section of document.getElementsByClassName("payment-type--current")) {
      _section.classList.remove("payment-type--current")
    }

    switch (e.currentTarget.value) {
      case "CreditCard":
      case "DebitCard": {

        if (e.currentTarget.value == "CreditCard") {
          this.sectionNewCardTarget.classList.add("payment-type--current")
        } else {
          this.sectionTokenCardsTarget.classList.add("payment-type--current")
          document.getElementById("bpmpi_paymentmethod").value = "Debit"
          document.getElementById("brand-american_express").style.display = 'none'
          document.getElementById("brand-diners_club").style.display = 'none'
          document.getElementById("brand-aura").style.display = 'none'
        }
        break
      }
      case "bank_slip": {
        this.sectionBankSlipTarget.classList.add("payment-type--current")
        break
      }
      case "debit_account": {
        this.sectionDebitAccountTarget.classList.add("payment-type--current")
        break
      }
    }
  }
  
  initIndex(){
    // const dataTable = new DataTable("#contracts-datatable", {
    this.dataTable = $("#budgets-datatable").dataTable({
      searchable: true,
      fixedHeight: true,
      initComplete: () => {
        // todo
      },
      language: {
        paginate: {
          previous: "Anterior",
          next: "Proximo",
        },
        lengthMenu: "Itens por página: _MENU_",
        processing: `<div class="spinner-border text-success" role="status"></div><p class="text-secondary">Processando...</p>`,
        search: "Pesquisar",
        zeroRecords: "Sem registros",
        info: "Mostrando _START_ até _END_ de _TOTAL_ registros",
      },
      labels: {
        placeholder: "Pesquisar...", // The search input placeholder
        perPage: "{select} itens por pagina", // per-page dropdown label
        noRows: "Nenhuma entrada encontrada", // Message shown when there are no search results
        info: "Exibindo {start} até {end} de {rows} itens", //
      },
      dom:
        "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12 p-0 asd'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      renderer: "bootstrap",
    });

    $("#budgets-date-range").daterangepicker({
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      autoApply: false,
      ranges: {
        Hoje: [moment(), moment()],
        Ontem: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Essa Semana": [moment().startOf("week"), moment().endOf("week")],
        "Últimos 7 dias": [moment().subtract(6, "days"), moment()],
        "Últimos 30 dias": [moment().subtract(29, "days"), moment()],
        "Esse Mês": [moment().startOf("month"), moment().endOf("month")],
        "Mês Passado": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      locale: {
        monthNames: "Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez".split(
          "_"
        ),
        daysOfWeek: "dom_2ª_3ª_4ª_5ª_6ª_Sáb".split("_"),
        customRangeLabel: "Customizado",
        format: "DD/MM/y",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
      },
    });

  }

  formActions() {
    this.loadMasks()
    document.addEventListener('nestedform:added', this.loadMasks);
    new Inputmask({ mask: ["999.999.999-99", "99.999.999/9999-99"]}).mask(document.getElementById("budget_client_attributes_document"));
    new Inputmask("99999-999").mask(document.getElementById("budget_client_attributes_cep"))
    new Inputmask("(99) 99999-9999").mask(document.getElementById("budget_client_attributes_phone"))
    new Inputmask({alias: "datetime", inputFormat: "dd/mm/yyyy", placeholder: "__/__/____"}).mask(document.getElementById("budget_client_attributes_birthday"))
    
  }

  calculateFullAmount(elem){
    var mainElement = (elem.target || elem).closest(".nested-fields")
    var amount = mainElement.querySelector(".bg-amount").value && Number(mainElement.querySelector(".bg-amount").value.replace(/[^0-9,-]+/g, "").replace(/,/, '.'))
    var quantity = Number(mainElement.querySelector(".bg-quantity").value)
    var fullAmount = amount && quantity && quantity * amount
    mainElement.querySelector(".bg-full-amount").innerHTML = `R$ ${this.formatMoney(fullAmount)}`
    return fullAmount
  }

  calculateAllAmounts(elem) {
    var fullAmount = Array.from(document.querySelectorAll(".nested-fields"))
      .map((e) => {
        return this.calculateFullAmount(e);
      })
      .reduce((total, num) => {
        return total + num;
      });
    this.bgFullAmountTarget.innerHTML = `R$ ${this.formatMoney(fullAmount)}`;
  }
  
  loadMasks(){
    // setTimeout(() =>{
    var amouts = Array.from(document.getElementsByClassName("bg-amount"))
    //.concat(Array.from(document.getElementsByClassName("bg-full-amount")))
    new Inputmask({
      alias: 'currency',
      prefix: 'R$ ',
      radixPoint: ',',
      groupSeparator: '.',
      numericInput: true
    }).mask(amouts)

    $("[data-provider=\"daterangepicker\"]").daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      autoApply: false,
      locale: {
        monthNames: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
        daysOfWeek: 'dom_2ª_3ª_4ª_5ª_6ª_Sáb'.split('_'),
        customRangeLabel: "Customizado",
        format: 'DD/MM/y',
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar"
      }
    })
    new Inputmask({alias: "datetime", inputFormat: "dd/mm/yyyy", placeholder: "__/__/____"}).mask(document.getElementById("budget_deadline"))

    $("#budget_client_id").select2({
      language: 'pt-BR',
      data: gon.clients,
      ajax: {
        url: "/search_clients",
        dataType: 'json',
        delay: 250,
        data: (params) => {
          return {
            q: params.term, // search term
            page: params.page
          }
        },
        processResults: (data, params) => {
          return {
            results: data,
          };
        },
      },
      templateResult: (data) => {
        return $(
          `<strong>${data.full_name || ""}</strong> - <small>${
            data.document || ""
          }</small>`
        );
      },
      templateSelection: (data) => {
        console.log(data)
        !data.selected && (document.getElementById("new_client").checked = false, document.getElementById("new_client").disabled = false)
        !data.selected && (data.id != undefined)           && (document.getElementById(`budget_client_attributes_id`).value = data.id)
        
        !data.selected && (data.first_name != undefined)   && (document.getElementById(`budget_client_attributes_first_name`).value = data.first_name)
        !data.selected && (data.last_name != undefined)    && (document.getElementById(`budget_client_attributes_last_name`).value = data.last_name)
        !data.selected && (data.birthday != undefined)     && (document.getElementById(`budget_client_attributes_birthday`).value = data.birthday)
        !data.selected && (data.document != undefined)     && (document.getElementById(`budget_client_attributes_document`).value = data.document || "")
        !data.selected && (data.telephone != undefined)    && (document.getElementById(`budget_client_attributes_telephone`).value = data.telephone)
        !data.selected && (data.phone != undefined)        && (document.getElementById(`budget_client_attributes_phone`).value = data.phone || "")
        
        !data.selected && (data.address != undefined)      && (document.getElementById(`budget_client_attributes_address`).value = data.address)
        !data.selected && (data.cep != undefined)          && (document.getElementById(`budget_client_attributes_cep`).value = data.cep || "")
        !data.selected && (data.city != undefined)         && (document.getElementById(`budget_client_attributes_city`).value = data.city)
        !data.selected && (data.complement != undefined)   && (document.getElementById(`budget_client_attributes_complement`).value = data.complement)
        !data.selected && (data.email != undefined)        && (document.getElementById(`budget_client_attributes_email`).value = data.email)
        !data.selected && (data.neighborhood != undefined) && (document.getElementById(`budget_client_attributes_neighborhood`).value = data.neighborhood)
        !data.selected && (data.number != undefined)       && (document.getElementById(`budget_client_attributes_number`).value = data.number)
        !data.selected && (data.state != undefined)        && (document.getElementById(`budget_client_attributes_state`).value = data.state)
        return data.full_name
      }
    })

      // new Inputmask({
      //   alias: 'currency',
      //   prefix: 'R$ ',
      //   radixPoint: ',',
      //   groupSeparator: '.'
      // }).mask(Array.from(document.getElementsByClassName("bg-full-amount")))
    // }, 250)
  }

  clearClients(e){
    (document.getElementById("new_client").checked = true, document.getElementById("new_client").disabled = true)
    document.getElementById(`budget_client_attributes_address`).value = null
    document.getElementById(`budget_client_attributes_birthday`).value = null
    document.getElementById(`budget_client_attributes_cep`).value = null
    document.getElementById(`budget_client_attributes_city`).value = null
    document.getElementById(`budget_client_attributes_complement`).value = null
    document.getElementById(`budget_client_attributes_cpf`).value = null
    document.getElementById(`budget_client_attributes_email`).value = null
    document.getElementById(`budget_client_attributes_first_name`).value = null
    document.getElementById(`budget_client_attributes_id`).value = null
    document.getElementById(`budget_client_attributes_last_name`).value = null
    document.getElementById(`budget_client_attributes_neighborhood`).value = null
    document.getElementById(`budget_client_attributes_number`).value = null
    document.getElementById(`budget_client_attributes_phone`).value = null
    document.getElementById(`budget_client_attributes_state`).value = null
    document.getElementById(`budget_client_attributes_telephone`).value = null
  }

  luhn_checksum(code) {
    var len = code.length
    var parity = len % 2
    var sum = 0
    for (var i = len - 1; i >= 0; i--) {
      var d = parseInt(code.charAt(i))
      if (i % 2 == parity) {
        d *= 2
      }
      if (d > 9) {
        d -= 9
      }
      sum += d
    }
    return sum % 10
  }

  formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
    }
  }

  delete(event) {
  	const id = event.target.dataset.value;
  	swal({
		title: "Você tem certeza?",
		text: "Não será possível recuperar esses dados!",
		icon: "warning",
		showCancelButton: true,
		buttons: true,
		// confirmButtonColor: "#DD6B55",
		confirmButtonText: "Sim, quero cancelar!",
		dangerMode: true
	}).then((willDelete) => {
		if(willDelete){
			fetch(`/budgets/${id}`, {
				method: "DELETE",
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': Rails.csrfToken()
				},
				body: JSON.stringify({
					"_method": "delete"
				})
			}).then( (response) => {
				if(response.ok){
					swal("Excluido!", "Orçamento cancelado com sucesso.", "success");
				} else {
					swal("Erro!", "Obtivemos um erro ao cancelar esse orçamento.", "error");
				}
			});
		}
	});
  } // Delete
}
