// // app/javascript/controllers/contract_term_controller.js
// import $ from "jquery"
// import "jquery-datatables-checkboxes"
// import "jquery-datatables-checkboxes/css/dataTables.checkboxes.css"
// import '../stylesheets/select2/select2.min.css'
// import 'select2/dist/js/select2.full.min'
// import 'select2/dist/js/i18n/pt-BR.js'
// import '../packs/bootstrap-notify.js'
// import "bootstrap-datepicker"
// import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js"

// $(function() {
//   var modal = $("#contract_termModal");

//   // Exibir o pop-up quando o usuário loga
//   modal.modal('show');

//   // Lógica para lidar com o botão "Concordo"
//   $(document).on('click', "#agreeBtn", function() {
//     // Fazer uma requisição AJAX para marcar o contrato como aceito no backend
//     $.post("/contract_term/agree", function(data) {
//       if (data.success) {
//         modal.modal('hide');
//       } else {
//         alert("Erro ao aceitar o contrato. Por favor, tente novamente.");
//       }
//     });
//   });
// });
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    console.log('Connect')
  }
}