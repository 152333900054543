import { Controller } from "stimulus";
import swal from 'sweetalert';
import Rails from '@rails/ujs';

export default class extends Controller {
    static targets = [ "tableBody" ] 
	// static targets = [ "output" ]

  connect() {
    if(this.data.has("actionForm")){
      this.load_form(this.data.get("actionForm"))
    }
  }

  load_form(action) {
  	// on load form
  }

  delete(event) {
  	const id = event.target.dataset.value;
  	swal({
		title: "Você tem certeza?",
		text: "Não será possível recuperar esses dados!",
		icon: "warning",
		showCancelButton: true,
		buttons: true,
		// confirmButtonColor: "#DD6B55",
		confirmButtonText: "Sim, quero remover!",
		dangerMode: true
	}).then((willDelete) => {
		if(willDelete){
			fetch(`/nfs/${id}`, {
				method: "DELETE",
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-Token': Rails.csrfToken()
				},
				body: JSON.stringify({
					"_method": "delete"
				})
			}).then( (response) => {
				console.log(response);
				if(response.ok){
					swal("Excluido!", "Os dados foram eliminados.", "success");
				} else {
					swal("Erro!", "Os dados não foram excluidos.", "error");
				}
			});
		}
	});
  }
	// Adicionar método para carregar mais dados via AJAX
  	loadMore() {
    const currentPage = this.data.get("currentPage") || 1;

    fetch(`/nfs?page=${parseInt(currentPage) + 1}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(response => response.json())
    .then(data => {
      const nfs = data.nfs;
      this.data.set("currentPage", data.current_page);

      nfs.forEach(nfsItem => {
        const row = document.createElement("tr");
        nfs.forEach(nfsItem => {
  			const row = document.createElement("tr");

  			// Adicione suas células (td) com os dados de nfsItem
			const clientCell = document.createElement("td");
			clientCell.textContent = nfsItem.client.full_name; // Substitua pelo atributo correto do seu modelo
			row.appendChild(clientCell);

			const dateCell = document.createElement("td");
			dateCell.textContent = nfsItem.updated_at; // Substitua pelo atributo correto do seu modelo
			row.appendChild(dateCell);

			const amountCell = document.createElement("td");
			amountCell.textContent = formatCurrency(nfsItem.amount); // Substitua pelo atributo correto do seu modelo
			row.appendChild(amountCell);

			const pdfCell = document.createElement("td");
		if (nfsItem.url_pdf) {
			const pdfLink = document.createElement("a");
			pdfLink.href = nfsItem.url_pdf;
			pdfLink.target = "_blank";
			pdfLink.textContent = "PDF";
  		pdfCell.appendChild(pdfLink);
		} else {
			pdfCell.textContent = "Indisponível";
		}
		row.appendChild(pdfCell);

  			const xmlCell = document.createElement("td");
		if (nfsItem.url_xml) {
			const xmlLink = document.createElement("a");
			xmlLink.href = nfsItem.url_xml;
			xmlLink.target = "_blank";
			xmlLink.textContent = "XML";
			xmlCell.appendChild(xmlLink);
		} else {
			xmlCell.textContent = "Indisponível";
		}
			row.appendChild(xmlCell);

  		this.tableBodyTarget.appendChild(row);
		});
      });
    });
  }
}
