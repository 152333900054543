// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="organizations">
//   <h1 data-target="organizations.output"></h1>
// </div>

import { Controller } from "stimulus";
import swal from 'sweetalert';
import Rails from '@rails/ujs';
import QrCode from "qrcode-generator"
import QrCd from "easyqrcodejs"

import "../customs/bootstrap-tagsinput"


export default class extends Controller {
  static targets = ["paymentTemplete", "customFieldTemplate", "templateFields"]

  link = ""
  connect() {
    new Inputmask("99.999.999/9999-99").mask(document.getElementById("organization_cnpj"));
    new Inputmask("[9][9][9][9][9][9]").mask(document.getElementById("amount"));
    this.link = document.organization.extern_link.value
    this.makeQR(this.link)
    $('.taginput').tagsinput()    
  }
  
  copy(e) {
    document.organization.extern_link.select()
    document.execCommand("copy")
    e.preventDefault()
  }
  
  changeAmount(e) {
    document.organization.extern_link.value = `${this.link}?amount=${e.currentTarget.value}`
    this.makeQR(document.organization.extern_link.value)
  }
  
  setupAmountChange() {
    var amountInput = document.getElementById('amount-input');
    var externLink = document.getElementById('extern-link');
    var makeQR = this.makeQR.bind(this);

    function changeAmount(e) {
      var amountValue = amountInput.value.replace(',', '.');
      var newExternLink = `${externLink.value.split('?')[0]}?amount=${amountValue}`;
      externLink.value = newExternLink;
      makeQR(newExternLink);
    }
    amountInput.addEventListener('input', changeAmount);
  }

  add_custom_field(event) {
    event.preventDefault()
    this.templateFieldsTarget.insertAdjacentHTML("beforebegin", this.customFieldTemplateTarget.innerHTML)
    $('.taginput').tagsinput();
  }
  
  change_type(event) {
    if (event.target.value == "select"){
      event.target.closest(".nested-fields").querySelector('.taginput').disabled = false
      event.target.closest(".nested-fields").querySelector('.taginput--selector').style.display = 'initial'
    } else {
      event.target.closest(".nested-fields").querySelector('.taginput--selector').style.display = 'none'
      event.target.closest(".nested-fields").querySelector('.taginput').disabled = true
    }
  }
  
  remove_association(event) {
    event.preventDefault()
    // console.log(event.target.closest(".nested-fields").target.childNodes())
    event.target.closest(".nested-fields").remove()
  }

  makeQR(data) {
    document.getElementById('placeHolder').innerHTML = ""
    var qr = new QrCd(document.getElementById("placeHolder"), {
      text: data,
      width: 350,
      height: 350,
      logo: document.getElementById("logo").src,
      // backgroundImage: "/econecta-big-icon-bg-t.png",
      // backgroundImageAlpha: 0.3,
      colorLight: "#FFF8EB",
      colorDark: "#1c354c",
      dotScale: 1,
      quietZone: 12,
      quietZoneColor: "#54c7c3",
      // title: $('[name="organization-name"]')[0].content,
      title: "Econekta",
      titleTop: 20,
      titleColor: "#FFF",
      titleBackgroundColor: "#54c7c3",
      titleHeight: 32,
      titleFont: "bold 25px Arial",
    })
    // var qr = QrCode(0, 'H');
    // qr.addData(data)
    // qr.make()
    // document.getElementById('placeHolder').innerHTML = qr.createImgTag(5);
  }

}
