// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="recurrences">
//   <h1 data-target="recurrences.output"></h1>
// </div>

import { Controller } from "stimulus";
import swal from 'sweetalert';
import Rails from '@rails/ujs';
import Inputmask from 'inputmask';
import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js";


export default class extends Controller {
  // static targets = [ "output" ]

  connect() {
    if(this.data.has("actionForm")){
      this.load_form()
    }
  }

  load_form() {
    // swal("Form Carregado");
    console.log($.fn)
    $('#recurrence_recurrence_validity').datepicker({startView: 1, minViewMode: 1, format: "mm/yyyy", language: "pt-BR", todayHighlight: true});
    new Inputmask("99/9999").mask(document.getElementById("recurrence_recurrence_validity"));
  }

  delete(event) {
    const id = event.target.dataset.value;
    swal({
    title: "Você tem certeza?",
    text: "Não será possível recuperar esses dados!",
    icon: "warning",
    showCancelButton: true,
    buttons: true,
    // confirmButtonColor: "#DD6B55",
    confirmButtonText: "Sim, quero remover!",
    dangerMode: true
  }).then((willDelete) => {
    if(willDelete){
      fetch(`/recurrences/${id}`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': Rails.csrfToken()
        },
        body: JSON.stringify({
          "_method": "delete"
        })
      }).then( (response) => {
        console.log(response);
        if(response.ok){
          swal("Excluido!", "Os dados foram eliminados.", "success");
        } else {
          swal("Erro!", "Os dados não foram excluidos.", "error");
        }
      });
    }
  });
  } // Delete
}
