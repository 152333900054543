// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="client_groups">
//   <h1 data-target="client_groups.output"></h1>
// </div>

import {
  Controller
} from "stimulus"
import swal from 'sweetalert'
import Rails from '@rails/ujs'
import $ from "jquery"
import "../customs/jquery-multi-select/jquery.multi-select"
import "../customs/jquery-multi-select/multi-select.css"


export default class extends Controller {
  // static targets = [ "output" ]

  connect() {
    if (this.data.has("actionForm")) {
      this.load_form(this.data.get("actionForm"))
    }
  }

  load_form(action) {
    console.log("Passou aqui")
    $('#client_group_client_ids').multiSelect({
      selectableHeader: `<input type="text" class="search-input form-control" autocomplete="off" placeholder="Pesquisar Clientes">`,
      selectionHeader: `<input type="text" class="search-input form-control" autocomplete="off" placeholder="Pesquisar Clientes Adicionados">`,
      afterInit: function (ms) {
        document.getElementById("loader").classList.add('d-none')
        document.getElementById("client-group-field").classList.remove('d-none')
        var that = this,
          $selectableSearch = that.$selectableUl.prev(),
          $selectionSearch = that.$selectionUl.prev(),
          selectableSearchString = '#' + that.$container.attr('id') + ' .ms-elem-selectable:not(.ms-selected)',
          selectionSearchString = '#' + that.$container.attr('id') + ' .ms-elem-selection.ms-selected'

        that.qs1 = $selectableSearch.quicksearch(selectableSearchString)
          .on('keydown', function (e) {
            if (e.which === 40) {
              that.$selectableUl.focus()
              return false
            }
          })

        that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
          .on('keydown', function (e) {
            if (e.which == 40) {
              that.$selectionUl.focus()
              return false
            }
          })
      },
      afterSelect: function () {
        this.qs1.cache()
        this.qs2.cache()
      },
      afterDeselect: function () {
        this.qs1.cache()
        this.qs2.cache()
      }
    })
  }

  delete(event) {
    const id = event.target.dataset.value
    swal({
      title: "Você tem certeza?",
      text: "Não será possível recuperar esses dados!",
      icon: "warning",
      showCancelButton: true,
      buttons: true,
      // confirmButtonColor: "#DD6B55",
      confirmButtonText: "Sim, quero remover!",
      dangerMode: true
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`/client_groups/${id}`, {
          method: "DELETE",
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken()
          },
          body: JSON.stringify({
            "_method": "delete"
          })
        }).then((response) => {
          console.log(response)
          if (response.ok) {
            swal("Excluido!", "Os dados foram eliminados.", "success")
          } else {
            swal("Erro!", "Os dados não foram excluidos.", "error")
          }
        })
      }
    })
  } // Delete
}