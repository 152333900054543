// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="contracts">
//   <h1 data-target="contracts.output"></h1>
// </div>
// backdrop-filter: blur(20px);

import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import Inputmask from "inputmask"
import $ from "jquery"

// import { DataTable } from "simple-datatables"
import "datatables.net"
import "datatables.net-bs4"

// import "jquery-datatables-checkboxes"
// import "jquery-datatables-checkboxes/css/dataTables.checkboxes.css"

// import 'air-datepicker'
// import 'air-datepicker/dist/js/i18n/datepicker.pt-BR.js'
// import 'air-datepicker/dist/css/datepicker.css'

import "customs/daterangepicker.js"
import "daterangepicker/daterangepicker.css"
import moment from "moment"

// require("air-datepicker").use(require("jquery"))

require("stylesheets/contracts");

export default class extends Controller {
  static targets = [
    "sectionCard",
    "sectionNewCard",
    "sectionBankSlip",
    "client",
    "paymentType",
    "card",
    "cardNumber",
    "cardHolder",
    "expirationDate",
    "ccv",
  ];

  card_hml = {
    autorizado: "0000 0000 0000 0001",
    nao_autorizado: "0000 0000 0000 0002",
    cartao_expirado: "0000 0000 0000 0003",
    cartao_bloqueado: "0000 0000 0000 0005",
    time_out: "0000 0000 0000 0006",
    cartao_cancelado: "0000 0000 0000 0007",
    problemas_cartao: "0000 0000 0000 0008",
    successful_or_time_out: "0000 0000 0000 0009",
  };

  drilldownEls = {};

  dataTable = null;

  connect() {
    this.selectContracts = []
    $("#payments-date-range").daterangepicker({
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      autoApply: false,
      ranges: {
        Hoje: [moment(), moment()],
        Ontem: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Essa Semana": [moment().startOf("week"), moment().endOf("week")],
        "Últimos 7 dias": [moment().subtract(6, "days"), moment()],
        "Últimos 30 dias": [moment().subtract(29, "days"), moment()],
        "Esse Mês": [moment().startOf("month"), moment().endOf("month")],
        "Mês Passado": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      locale: {
        monthNames: "Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez".split(
          "_"
        ),
        daysOfWeek: "dom_2ª_3ª_4ª_5ª_6ª_Sáb".split("_"),
        customRangeLabel: "Customizado",
        format: "DD/MM/y",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
      },
      
    });
    // const popcorn = document.querySelector('[data-tooltip="true"]');
    // const tooltip = document.querySelector('[data-tooltip="true"]');
    // createPopper(popcorn, tooltip, {
    //   placement: 'down',
    // });

    if (this.data.has("actionForm")) {
      switch (this.data.get("actionForm")) {
        case "new": {
          this.on_new();
          break;
        }
        case "edit": {
          this.on_edit();
          break;
        }
      }
    }

    // $.extend(true, $.dataTable.defaults, {
    //   dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
    //     "<'row'<'col-sm-12 p-0 asd'tr>>" +
    //     "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
    //   renderer: 'bootstrap'
    // });

    // const dataTable = new DataTable("#contracts-datatable", {
    this.dataTable = $("#contracts-datatable").dataTable({
      searchable: true,
      ordering: false,
      fixedHeight: true,
      initComplete: () => {
        // todo
      },
      language: {
        paginate: {
          previous: "Anterior",
          next: "Proximo",
        },
        lengthMenu: "Itens por página: _MENU_",
        processing: `<div class="spinner-border text-success" role="status"></div><p class="text-secondary">Processando...</p>`,
        search: "Pesquisar",
        zeroRecords: "Sem registros",
        info: "Mostrando _START_ até _END_ de _TOTAL_ registros",
      },
      labels: {
        placeholder: "Pesquisar...", // The search input placeholder
        perPage: "{select} itens por pagina", // per-page dropdown label
        noRows: "Nenhuma entrada encontrada", // Message shown when there are no search results
        info: "Exibindo {start} até {end} de {rows} itens", //
      },
      dom:
        "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
        "<'row'<'col-sm-12 p-0 asd'tr>>" +
        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
      renderer: "bootstrap",
      columnDefs: [
        {
          targets: 0,
          checkboxes: {
            selectRow: true
          }
        }
      ],
      select: {
        style: 'multi'
      },
    });

    window.tdTb = this.dataTable
    window.tdTb.api().rows({ filter : 'applied'})

    this.dataTable.api().on( 'search.dt', () => {
      const dataNodes = Array.from(this.dataTable.api().rows({ filter : 'applied'}).nodes())

      const dataLate = dataNodes && dataNodes.length > 0 && dataNodes.map(e => { return parseFloat(e.dataset.late) })
      const dataScheduled = dataNodes && dataNodes.length > 0 && dataNodes.map(e => { return parseFloat(e.dataset.scheduled) })
      const dataProcessed = dataNodes && dataNodes.length > 0 && dataNodes.map(e => { return parseFloat(e.dataset.processed) })
      const dataTotal = dataNodes && dataNodes.length > 0 && dataNodes.map(e => { return parseFloat(e.dataset.total) })
      console.log("Total:", dataTotal )
      // console.log("dataProcessed:", this.formatMoney(dataProcessed.reduce((t,e) => t + e)) )
      // console.log("dataScheduled:", this.formatMoney(dataScheduled.reduce((t,e) => t + e)) )
      // console.log("dataLate:", this.formatMoney(dataLate.reduce((t,e) => t + e)) )

      document.getElementById("total-card-value").innerHTML = `R$ ${dataTotal ? this.formatMoney(dataTotal.reduce((t,e) => t + e)) : '0,00'}`
      document.getElementById("late-card-value").innerHTML = `R$ ${dataLate ? this.formatMoney(dataLate.reduce((t,e) => t + e)) : '0,00'}`
      document.getElementById("prev-card-value").innerHTML = `R$ ${dataScheduled ? this.formatMoney(dataScheduled.reduce((t,e) => t + e)) : '0,00'}`
      document.getElementById("paid-card-value").innerHTML = `R$ ${dataProcessed ? this.formatMoney(dataProcessed.reduce((t,e) => t + e)) : '0,00'}`
    })
    
    // console.log(this.dataTable.api().rows())

    // this.dataTable.on('click', '.ckbx', _ => {
    //   console.log(this.dataTable.api().rows())
    // })

    // this.dataTable.fnGetNodes().forEach(el => {
    //   el.querySelector('input').checked = true
    // })

    // $('#contracts-datatable').on('page.dt', function () {
    //   setTimeout(() => {
    //     $('[rel="tooltip"]').tooltip()
    //   }, 100)
    // });

    // setTimeout(() => {
    //   $('[rel="tooltip"]').tooltip()
    // }, 100)

    // document.querySelectorAll('[data-tooltip]').forEach((el) => {
    //   var tp = document.querySelector(el.dataset.tooltip)
    //   console.log(tp)
    //   createPopper(el, tp, {
    //     placement: 'left',
    //   });

    // })
    // const button = document.querySelector('#button');
    // const tooltip = document.querySelector('#tooltip');

    // var columns = [...document.querySelectorAll(".dt-columns")].map((i) => {
    //   console.log(i);
    //   return i.dataset.column
    // })
    // this.dataTable.api().columns()[2].search("CT08E").draw()

    // yadcf.init(this.dataTable, [
    //   {
    //     column_number: 2,
    //     filter_type: "text",
    //     filter_container_id: "filter-code"
    //   },
    // ]);
  }

  formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
    }
  }

  postponeModal(event) {
    var currentInterval = setInterval(() => {
      if (document.querySelector("#date")) {
        new Inputmask("99/99/2099").mask(document.getElementById("date"));
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        $("#date").datepicker({
          language: "pt-BR",
          minDate: tomorrow,
        });
        clearInterval(currentInterval);
      }
    }, 100);
  }

  onCheckedContract(event) {
    if(event.target.checked)
      this.selectContracts.push(event.target.dataset.id)
    else
      this.selectContracts.pop(event.target.dataset.id)

    console.log("Selecteds:", this.selectContracts)
  }

  searchTable(event) {
    this.dataTable
      .api()
      .columns(event.currentTarget.dataset.columnIndex)
      .search(event.currentTarget.value)
      .draw();
  }

  drillDownContract(event) {
    var dataTable = this.dataTable;
    var el = event.currentTarget;
    var row = $(el.offsetParent).parents("tr")[0];
    var currentController = this;

    // console.log("Row is Open:", row.dataset.isOpen)

    if (row.dataset.isOpen != "true") {
      el.classList.remove("nc-simple-add");
      el.classList.add("sp");
      el.classList.add("sp-circle");
      fetch(`/contracts/${row.dataset.id}/detail`)
        .then((response) => {
          if (response.ok) {
            response.text().then((text) => {
              el.classList.remove("nc-simple-add");
              el.classList.remove("sp");
              el.classList.remove("sp-circle");
              el.classList.add("nc-simple-delete");
              row.dataset.isOpen = "true";
              var nDetailsRow = dataTable.fnOpen(row, text, "details");
              currentController.drilldownEls[row.dataset.id] = nDetailsRow;
              $("div.innerDetails", nDetailsRow).slideDown();
            });
          } else {
            console.log("Network response was not ok.");
          }
        })
        .catch((error) => {
          console.log(
            "There has been a problem with your fetch operation: " +
              error.message
          );
        });
    } else {
      el.classList.remove("nc-simple-delete");
      el.classList.add("nc-simple-add");
      var nDetailsRow = currentController.drilldownEls[row.dataset.id];
      $("div.innerDetails", nDetailsRow).slideUp(() => {
        row.dataset.isOpen = null;
        currentController.drilldownEls[row.dataset.id] = null;
        dataTable.fnClose(
          row,
          currentController.drilldownEls[row.dataset.id],
          "details"
        );
      });
    }
  }

  onPostSuccess(event) {
    swal({
      title: "Sucesso!",
      text: "Transação feita com sucesso!",
      icon: "success",
      dangerMode: true,
    }).then((e) => {
      console.log(event);
      Turbolinks.visit(event.detail[0].data.url);
    });
  }

  onPostError(event) {
    console.error("Erro", event);
    // alert(event.detail[0].error)

    swal({
      title: "Ops!",
      text: event.detail[0].error,
      icon: "warning",
      dangerMode: false,
    });
  }

  update() {
    console.log("Update called!!");
  }

  beforeSendForm() {
    $(".modal").modal("show");
  }

  onCompleteForm() {
    $(".modal").modal("hide");
    // Previnir congelamento do modal
    setTimeout(() => $(".modal").modal("hide"), 500);
  }

  on_new() {
    //chamada para colocar a mascara no campo de valor em novo contrato;
    //.concat(Array.from(document.getElementsByClassName("bg-full-amount")))
    var amouts = Array.from(document.getElementsByClassName("bg-amount"))
      new Inputmask({
      alias: 'currency',
      prefix: 'R$ ',
      radixPoint: ',',
      groupSeparator: '.',
      numericInput: true
    }).mask(amouts);

    // this.sectionBankSlipTarget.classList.toggle("payment-type--current")
    // new Inputmask({
    //   regex: "^(3[0-3]|0[1-9]|\d)\/(1[0-2]|0[1-9]|\d)\/(20[0-9][0-9])$"
    // }).mask(document.getElementById("contract_first_payment"));

    new Inputmask("99/99/2099").mask(
      document.getElementById("contract_first_payment")
    );

    // $("[data-provider=\"xdatepicker\"]").datepicker({
    //   // startView: 3,
    //   singleDatePicker: true,
    //   showDropdowns: true,
    //   language: "pt-BR"
    // });

    $('[data-provider="daterangepicker"]').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      autoApply: false,
      locale: {
        monthNames: "Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez".split(
          "_"
        ),
        daysOfWeek: "dom_2ª_3ª_4ª_5ª_6ª_Sáb".split("_"),
        customRangeLabel: "Customizado",
        format: "DD/MM/y",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
      },
    });

    $('[data-provider="datetimepicker"]').daterangepicker({
      timePicker: true,
      timePicker24Hour: true,
      singleDatePicker: true,
      showDropdowns: true,
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      autoApply: false,
      locale: {
        monthNames: "Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez".split(
          "_"
        ),
        daysOfWeek: "dom_2ª_3ª_4ª_5ª_6ª_Sáb".split("_"),
        customRangeLabel: "Customizado",
        // format: 'DD/MM/y HH:mm',
        format: "DD/MM/YYYY HH:mm",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
      },
    });
    // new Inputmask("99/99/2099").mask(document.getElementById("contract_first_payment"));

    new Inputmask("9999 9999 9999 999[9] [9][9][9]").mask(
      document.getElementById("card_number")
    );

    // new Inputmask("99/99/9999").mask(document.getElementById("contract_first_payment"));
    new Inputmask({ regex: "^(1[0-2]|0[1-9]|d)/([0-9][0-9])$" }).mask(
      document.getElementById("expiration_date")
    );

    new Inputmask("999[9]").mask(document.getElementById("ccv"));
    this.payment_change();
    this.change_card(null);
  }

  on_edit() {
    this.on_new();
  }

  change_card(event) {
    if (!event || event.currentTarget.value == "") {
      this.sectionNewCardTarget.classList.remove("payment-type");
    } else {
      this.sectionNewCardTarget.classList.add("payment-type");
    }
  }

  card_hml_change(e) {
    if (e.currentTarget.value != "") {
      this.cardNumberTarget.value = this.card_hml[e.currentTarget.value];
      this.cardHolderTarget.value = "Liz Neves Neto";
      this.expirationDateTarget.value = "01/25";
      this.ccvTarget.value = "332";

      this.cardNumberTarget.readOnly = true;
      this.cardHolderTarget.readOnly = true;
      this.expirationDateTarget.readOnly = true;
      this.ccvTarget.readOnly = true;
    } else {
      this.cardNumberTarget.value = "";
      this.cardHolderTarget.value = "";
      this.expirationDateTarget.value = "";
      this.ccvTarget.value = "";

      this.cardNumberTarget.readOnly = false;
      this.cardHolderTarget.readOnly = false;
      this.expirationDateTarget.readOnly = false;
      this.ccvTarget.readOnly = false;
    }
  }

  payment_change(e) {
    for (let _section of document.getElementsByClassName(
      "payment-type--current"
    )) {
      _section.classList.remove("payment-type--current");
    }

    switch (this.paymentTypeTarget.value) {
      case "CreditCard":
      case "DebitCard": {
        this.sectionCardTarget.classList.add("payment-type--current");
        if (e.currentTarget.value == "CreditCard") {
          document.getElementById(
            "brand-american_express"
          ).style.display = null;
          document.getElementById("brand-diners_club").style.display = null;
          document.getElementById("brand-aura").style.display = null;
        } else {
          document.getElementById("brand-american_express").style.display =
            "none";
          document.getElementById("brand-diners_club").style.display = "none";
          document.getElementById("brand-aura").style.display = "none";
        }
        break;
      }
      case "bank_slip": {
        this.sectionBankSlipTarget.classList.add("payment-type--current");
        break;
      }
    }
  }
}
